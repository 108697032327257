import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import snackbarReducer from "./snackbarSlice";
import imageCropModalOpenReducer from "./imageCropModalOpenSlice";
import themeReducer from "./themeSlice";
import customerReducer from "./customerSlice";
import {emptyApi} from "./emptyApi";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["theme"],
};

const rootReducer = combineReducers({
    customer: customerReducer,
    theme: themeReducer,
    snackbar: snackbarReducer,
    imageCropModalOpen: imageCropModalOpenReducer,
    [emptyApi.reducerPath]: emptyApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(emptyApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
