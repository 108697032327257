import DownloadButton from "./DownloadButton";
import {DocumentSource} from "../../../../state/layerApi";
import {Button} from "@mui/material";
import * as React from "react";
import {Source} from "../../../../shared/types";

export interface ContentProps {
    source: Source;
    value?: string;
}

export default function Content({source, value}: ContentProps) {
    if (source.url) {
        return (
            <a href={source.url} target="_blank" rel="noreferrer">
                {source.url}
            </a>
        );
    } else if (source.type === "document") {
        return <DownloadButton id={source.id} filename={(source as DocumentSource).filename}/>;
    } else if (source.type === "suggestion") {
        return (
            <>
                <Button
                    variant="text"
                    size="small"
                    sx={{p: 0, textAlign: "left"}}
                    onClick={() => {
                    }}
                >
                    Suggestion
                </Button>
            </>
        );
    } else {
        return <>{value}</>;
    }
}