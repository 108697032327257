import { Box, Divider, ListItem, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import pageProps from "../../shared/Pages";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

const ParentNavListing = ({
  parentName,
  expanded,
  setExpanded,
}: {
  parentName: string;
  expanded: boolean;
  setExpanded: (open: boolean) => void;
}) => {
  const navigate = useNavigate();
  const children = pageProps.filter((child) => child.parent === parentName);
  const parentActive = children.some(
    (child) => child.path === window.location.pathname,
  );
  const [open, setOpen] = React.useState(parentActive);

  if (expanded) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <ListItem
          onClick={() => {
            setOpen(!open);
          }}
          sx={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            gap: 1,
            justifyContent: "space-between",
            p: 2,
            borderRadius: 2,
            ":hover": {
              backgroundColor: "grey.200",
              cursor: "pointer",
            },
            ...(parentActive && {
              bgcolor: "primary.100",
              color: "primary.main",
              ":hover": { backgroundColor: "primary.200" },
            }),
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            {children[0].icon}
            <Typography
              variant="h4"
              sx={{
                fontWeight: parentActive ? "600" : "500",
              }}
            >
              {parentName}
            </Typography>
          </Box>
          <ExpandMoreIcon sx={{ rotate: open ? "180deg" : "0" }} />
        </ListItem>
        {open && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, pl: 1 }}>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
              }}
            >
              {children.map((child) => (
                <ListItem
                  onClick={() => navigate(child.path)}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    p: 2,
                    py: 1.5,
                    borderRadius: 2,
                    ":hover": {
                      backgroundColor: "grey.200",
                      cursor: "pointer",
                    },
                    ...(child.path === window.location.pathname && {
                      bgcolor: "primary.100",
                      color: "primary.main",
                      ":hover": { backgroundColor: "primary.200" },
                    }),
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight:
                        child.path === window.location.pathname ? "600" : "500",
                    }}
                  >
                    {child.name}
                  </Typography>
                </ListItem>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Tooltip title={parentName} disableInteractive enterDelay={500}>
        <ListItem
          onClick={() => {
            setExpanded(true);
            setOpen(true);
          }}
          sx={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            gap: 1,
            p: 2,
            borderRadius: 2,
            ":hover": {
              backgroundColor: "grey.200",
              cursor: "pointer",
            },
            justifyContent: "center",
            ...(parentActive && {
              bgcolor: "primary.100",
              color: "primary.main",
              ":hover": { backgroundColor: "primary.200" },
            }),
          }}
        >
          {children[0].icon}
        </ListItem>
      </Tooltip>
    );
  }
};

export default ParentNavListing;
