import * as React from "react";
import {Button} from "@mui/material";
import {useAuthContext} from "../../../../contexts/AuthContext";

interface DownloadButtonProps {
    id: number;
    filename: string;
}

export default function DownloadButton({id, filename}: DownloadButtonProps) {
    const user = useAuthContext();
    const onClick = async () => {
        if (user === null) return;
        const url = `${process.env.REACT_APP_LAYER_URL}sources/${id}/download`;
        return user.getIdToken()
            .then(token => {
                const options = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/octet-stream',
                        'Firebase-Auth': token,
                    }
                };
                return fetch(url, options)
            })
            .then(response => response.blob())
            .then(blobLike => {
                const objectUrl = window.URL.createObjectURL(blobLike);
                const link = document.createElement('a');
                link.download = filename;
                link.href = objectUrl;
                link.click();
                window.URL.revokeObjectURL(objectUrl);
            })
            .catch(err => console.log(err));
    }

    return (
        <Button
            variant="text"
            size="small"
            sx={{p: 0, textAlign: "left"}}
            onClick={onClick}
        >
            {filename}
        </Button>
    );
}