// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {GoogleAuthProvider, getAuth, connectAuthEmulator, setPersistence, browserLocalPersistence} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBVivQG7Anr2f-6C6oXPLUcHLiUL0xrG0w",
    authDomain: "layer-backend.firebaseapp.com",
    projectId: "layer-backend",
    storageBucket: "layer-backend.appspot.com",
    messagingSenderId: "692435806978",
    appId: "1:692435806978:web:b556776622d2999333701b",
    measurementId: "G-69WC2S36QN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
    .catch((err) => {
        console.error(err);
    });

// Use Emulator
if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

export const provider = new GoogleAuthProvider();
export const analytics = getAnalytics(app);

export default app;