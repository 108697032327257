import {Box} from "@mui/material";
import NavDrawer from "./nav/NavDrawer";
import Dashboard from "./Dashboard/Dashboard";
import {useAuthContext} from "../contexts/AuthContext";
import Snackbar from "./snackbar/Snackbar";
import Auth from "./Auth/Auth";
import {ReactNode} from "react";
import {CustomerContextProvider} from "../contexts/CustomerContext";

const App = () => {
    const user = useAuthContext();

    let body: ReactNode;
    if (user === null) {
        body = <>
            <Auth/>
            <Snackbar/>
        </>;
    } else {
        body = <>
            <CustomerContextProvider>
                <NavDrawer/>
                <Dashboard/>
                <Snackbar/>
            </CustomerContextProvider>
        </>;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: 0,
            }}
        >
            {body}
        </Box>
    );
};

export default App;
