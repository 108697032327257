import React, { useCallback, useMemo, useRef, useState } from "react";
import ModalDialog from "./ModalDialog";
import ReactCrop, { type Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../state/store";
import { setImageCropModalOpen } from "../state/imageCropModalOpenSlice";

interface iImageCropDialog {
  aspectRatio?: number;
  minSize: { width: number; height: number };
  maxSize?: { width: number; height: number };
  originalImage: File;
  imageAltText?: string;
  handleCropComplete: (image: Blob) => void;
}

const ImageCropDialog = ({
  aspectRatio,
  minSize,
  maxSize,
  originalImage,
  imageAltText,
  handleCropComplete,
}: iImageCropDialog) => {
  const dispatch = useDispatch<AppDispatch>();

  const initialCropState: Crop = useMemo(
    () => ({
      unit: "px",
      width: minSize.width,
      height: minSize.height,
      x: 0,
      y: 0,
    }),
    [minSize.width, minSize.height],
  );

  const imageUrl = useSelector(
    (state: RootState) => state.imageCropModalOpen.imageUrl,
  );

  const imageCropDialogOpen = useSelector(
    (state: RootState) => state.imageCropModalOpen.open,
  );

  const [crop, setCrop] = useState<Crop>(initialCropState);

  const handleCloseImageCropDialog = useCallback(() => {
    dispatch(
      setImageCropModalOpen({
        open: false,
        imageUrl: "",
        originalFileName: "",
        originalFileType: "image/jpeg",
        minSize: { height: 10, width: 10 },
      }),
    );
    setCrop(initialCropState);
  }, [dispatch, setCrop, initialCropState]);

  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef<string | null>(null);

  const handleSaveCrop = useCallback(async () => {
    const image = imgRef.current;
    if (!image || !crop) {
      throw new Error("Crop or image does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const canvas = document.createElement("canvas");
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    canvas.toBlob((blob) => {
      if (blob) {
        if (blobUrlRef.current) {
          URL.revokeObjectURL(blobUrlRef.current);
        }
        blobUrlRef.current = URL.createObjectURL(blob);

        handleCropComplete(blob);

        handleCloseImageCropDialog();
      }
    }, "image/png");
  }, [crop, handleCropComplete, handleCloseImageCropDialog]);

  return (
    <ModalDialog
      label="Crop Image"
      modalOpen={imageCropDialogOpen}
      handleClose={handleCloseImageCropDialog}
      hasDefaultWidth={false}
    >
      <ReactCrop
        crop={crop}
        onChange={(c) => setCrop(c)}
        aspect={aspectRatio}
        minHeight={minSize.height}
        minWidth={minSize.width}
        maxHeight={maxSize ? maxSize.height : undefined}
        maxWidth={maxSize ? maxSize.width : undefined}
        style={{ maxHeight: "70vh" }}
        keepSelection
      >
        <img
          src={imageUrl}
          ref={imgRef}
          alt={imageAltText ? imageAltText : "Cropped image preview"}
          style={{}}
        />
      </ReactCrop>
      <Button variant="contained" onClick={handleSaveCrop}>
        Crop and Save
      </Button>
    </ModalDialog>
  );
};

export default ImageCropDialog;
