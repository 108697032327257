import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  useRagConfigGetRagConfigurationGetQuery,
  useRagConfigUpdateRagConfigurationPutMutation,
} from "../../../state/layerApi";
import EditIcon from "@mui/icons-material/Edit";
import Form, { Field } from "../../../shared/Form";
import { FieldValues } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

const RAGConfiguration = () => {
  const { data: RAGConfiguration } = useRagConfigGetRagConfigurationGetQuery();
  const [putRAGConfiguration, { isLoading: isPutRAGConfigurationLoading }] =
    useRagConfigUpdateRagConfigurationPutMutation();

  const [systemPrompt, setSystemPrompt] = React.useState<string>("");

  const [editMode, setEditMode] = React.useState<boolean>(false);

  useEffect(() => {
    if (RAGConfiguration) {
      setSystemPrompt(RAGConfiguration.system_prompt);
    }
  }, [RAGConfiguration]);

  const RAG_CONFIGURATION_FIELDS: Field[] = [
    {
      fieldName: "systemPrompt",
      fieldLabel: "System Prompt",
      fieldType: "markdown",
      fieldRequired: true,
      fieldDefaultValue: systemPrompt,
    },
  ];

  async function handleUpdateRAGConfiguration(values: FieldValues) {
    await putRAGConfiguration({
      ragConfigurationUpdate: { system_prompt: values.systemPrompt },
    }).then(() => {
      setEditMode(false);
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        border: 1,
        borderColor: "divider",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderBottomColor: "divider",
        }}
      >
        <Typography variant="h3">RAG Settings</Typography>
        {!editMode && (
          <Button
            variant="contained"
            onClick={() => {
              setEditMode(!editMode);
            }}
            sx={{
              px: 2,
              py: 1,
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              gap: 1,
            }}
          >
            <EditIcon />
            Edit
          </Button>
        )}
      </Box>
      <Box sx={{ p: 2 }}>
        {!editMode && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography variant="h4">System Prompt</Typography>
            <ReactMarkdown remarkPlugins={[gfm]} className="markdown">
              {systemPrompt}
            </ReactMarkdown>
          </Box>
        )}
        {editMode && (
          <Form
            fields={RAG_CONFIGURATION_FIELDS}
            handleFormSubmit={handleUpdateRAGConfiguration}
            submitButtonLabel="Save"
            submitButtonLoading={isPutRAGConfigurationLoading}
          />
        )}
      </Box>
    </Box>
  );
};

export default RAGConfiguration;
