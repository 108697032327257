function handleGuardConditions(
  sites: string[],
  currentDepth: number,
  maxDepth: number,
): Record<string, string[]> | null {
  if (currentDepth >= maxDepth || sites.length === 0) {
    return { ungrouped: [] };
  }

  if (sites.length === 1) {
    return { ungrouped: [sites[0]] };
  }

  // No guard condition met, return null
  return null;
}

export function groupSites(
  sites: string[],
  currentDepth: number = 1,
  maxDepth: number = 3,
  groupByDomain: boolean = false,
) {
  const guardResult = handleGuardConditions(sites, currentDepth, maxDepth);
  if (guardResult !== null) {
    return guardResult;
  }

  if (groupByDomain && currentDepth === 1) {
    const domainGroups: Record<string, string[]> = {};
    sites.forEach((site) => {
      const siteURL = new URL(site);
      const groupKey = siteURL.protocol + "//" + siteURL.hostname + "/";
      if (!domainGroups[groupKey]) {
        domainGroups[groupKey] = [];
      }
      domainGroups[groupKey].push(site);
    });

    Object.keys(domainGroups).forEach((key) => {
      const nestedGroups = groupSites(
        domainGroups[key],
        currentDepth + 1,
        maxDepth,
        false,
      );
      Object.keys(nestedGroups).forEach((nestedKey) => {
        if (nestedKey !== "ungrouped") {
          domainGroups[key + "," + nestedKey] = nestedGroups[nestedKey];
          nestedGroups[nestedKey].forEach((link) => {
            domainGroups[key] = domainGroups[key].filter((l) => l !== link);
          });
        } else {
          nestedGroups[nestedKey].forEach((link) => {
            const siteURL = new URL(link);
            const groupKey = siteURL.protocol + "//" + siteURL.hostname + "/";
            if (!domainGroups[groupKey].includes(link)) {
              domainGroups[groupKey].push(link);
            }
          });
        }
      });
    });

    Object.keys(domainGroups).forEach((key) => {
      if (
        domainGroups[key].length === 1 &&
        !Object.keys(domainGroups).some(
          (k) => k.split(",")[0] === key && k !== key,
        )
      ) {
        if (!domainGroups["ungrouped"]) {
          domainGroups["ungrouped"] = [];
        }
        domainGroups["ungrouped"].push(...domainGroups[key]);
        delete domainGroups[key];
      }
    });

    return domainGroups;
  }

  const pathSegmentsList = sites.map((site) => {
    const url = new URL(site);
    if (url.pathname === "/") return [];
    return url.pathname.split("/").filter(Boolean);
  });

  let varyingSegmentIndex = 0;
  while (
    varyingSegmentIndex < pathSegmentsList[0].length &&
    pathSegmentsList.every(
      (segments) =>
        segments[varyingSegmentIndex] ===
        pathSegmentsList[0][varyingSegmentIndex],
    )
  ) {
    varyingSegmentIndex++;
  }

  const groups: Record<string, string[]> = {};
  pathSegmentsList.forEach((segments, index) => {
    const url = new URL(sites[index]);
    const pathname = url.pathname;
    const protocol = url.protocol;
    const hostname = url.hostname;
    const groupUrl = segments[varyingSegmentIndex]
      ? sites[index].slice(
          0,
          protocol.length +
            2 +
            hostname.length +
            pathname.indexOf(segments[varyingSegmentIndex]) +
            segments[varyingSegmentIndex].length,
        )
      : "ungrouped";
    const key = groupUrl || "ungrouped";
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(sites[index]);
  });

  Object.keys(groups).forEach((key) => {
    if (groups[key].length === 1) {
      if (!groups["ungrouped"]) {
        groups["ungrouped"] = [];
      }
      groups["ungrouped"].push(...groups[key]);
      delete groups[key];
    }
  });

  Object.keys(groups).forEach((key) => {
    if (key !== "ungrouped") {
      const nestedGroups = groupSites(
        groups[key],
        currentDepth + 1,
        maxDepth,
        false,
      );
      Object.keys(nestedGroups).forEach((nestedKey) => {
        if (nestedKey !== "ungrouped") {
          groups[key + "," + nestedKey] = nestedGroups[nestedKey];
          groups[key].forEach((link) => {
            groups[key] = groups[key].filter((l) => l !== link);
          });
        } else {
          nestedGroups[nestedKey].forEach((link) => {
            if (!groups[key].includes(link)) {
              groups[key].push(link);
            }
          });
        }
      });
    }
  });

  sites.forEach((site) => {
    if (!Object.values(groups).some((group) => group.includes(site))) {
      if (groups[site]) {
        groups[site].push(site);
      } else if (site.endsWith("/") && groups[site.slice(0, -1)]) {
        groups[site.slice(0, -1)].push(site);
      } else {
        if (!groups["ungrouped"]) {
          groups["ungrouped"] = [];
        }
        groups["ungrouped"].push(site);
      }
    }
  });

  return groups;
}
