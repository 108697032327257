import {Box} from "@mui/material";
import React from "react";
import {useExtensionsGetExtensionsGetQuery} from "../../../../state/layerApi";
import {useDownloadExtensionObjectQuery} from "../../../../state/emptyApi";
import EditExtension from "./EditExtension";
import ViewExtension from "./ViewExtension";

// --------------- Components ---------------
const VSCodeExtension = () => {
    const [extensionId, setExtensionId] = React.useState<number>(0);
    const [editMode, setEditMode] = React.useState<boolean>(false);

    const {data: extensions, isLoading: extensionLoading} = useExtensionsGetExtensionsGetQuery();
    const {data: readmeData, isLoading: readmeLoading} = useDownloadExtensionObjectQuery(
        {
            extensionId: extensionId,
            specialObjectName: "README.md",
        },
        {
            skip: extensionId === 0,
        }
    );
    const {data: logoData, isLoading: logoLoading} = useDownloadExtensionObjectQuery(
        {
            extensionId: extensionId,
            specialObjectName: "logo.png",
        },
        {
            skip: extensionId === 0,
        }
    );

    const loading = extensionLoading || readmeLoading || logoLoading || extensions === undefined || extensions.length === 0 || readmeData === undefined || logoData === undefined;

    React.useEffect(() => {
        if (extensions === undefined || extensions.length === 0) return;
        setExtensionId(extensions[0].id);
    }, [extensions, setExtensionId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    const extension = extensions[0];

    return (
        <Box
            sx={{
                p: 2,
                textAlign: "left",
                border: 1,
                borderRadius: 1,
                borderColor: "divider",
            }}
        >
            {editMode ? (
                <EditExtension
                    deactivateEditMode={() => setEditMode(false)}
                    initialValues={{
                        name: extensions[0].name,
                        participantName: extensions[0].participant_name,
                        participantDescription: extensions[0].participant_description,
                        displayName: extensions[0].display_name,
                        description: extensions[0].description,
                        logoData: logoData,
                        readmeData: readmeData,
                    }}
                    extensionId={extensions[0].id}
                />
            ) : (
                <ViewExtension
                    extension={{
                        ...extension,
                        readme_data: readmeData,
                        logo_data: logoData,
                    }}
                    activateEditMode={() => setEditMode(true)}
                />
            )}
        </Box>
    );
};

export default VSCodeExtension;
