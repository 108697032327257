import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ImageCropModalOpenState {
  open: boolean;
  imageUrl: string;
  originalFileName: string;
  originalFileType: string;
  aspectRatio?: number;
  minSize: { width: number; height: number };
  maxSize?: { width: number; height: number };
}

interface ImageCropModalOpenPayload {
  open: boolean;
  imageUrl: string;
  originalFileName: string;
  originalFileType: string;
  aspectRatio?: number;
  minSize: { width: number; height: number };
  maxSize?: { width: number; height: number };
}

const initialState: ImageCropModalOpenState = {
  open: false,
  imageUrl: "",
  originalFileName: "",
  originalFileType: "image/jpeg",
  minSize: { width: 10, height: 10 },
};

const imageCropModalOpenSlice = createSlice({
  name: "imageCropModalOpen",
  initialState,
  reducers: {
    setImageCropModalOpen(
      state,
      action: PayloadAction<ImageCropModalOpenPayload>,
    ) {
      state.open = action.payload.open;
      state.imageUrl = action.payload.imageUrl;
      state.originalFileName = action.payload.originalFileName;
      state.originalFileType = action.payload.originalFileType;
      state.aspectRatio = action.payload.aspectRatio;
      state.minSize = action.payload.minSize;
      state.maxSize = action.payload.maxSize;
    },
  },
});

export const { setImageCropModalOpen } = imageCropModalOpenSlice.actions;
export default imageCropModalOpenSlice.reducer;
