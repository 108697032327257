export function formatDateTime(value: Date) {
    return `${
        value.toLocaleDateString() === new Date().toLocaleDateString()
            ? "Today"
            : value.toLocaleString("default", {
                day: "numeric",
                month: "short",
            }) +
            (new Date().getFullYear() === value.getFullYear()
                ? ""
                : ", " + value.getFullYear())
    }, ${value.toLocaleTimeString("default", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    })}`;
}