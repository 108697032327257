import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CustomerState {
    id?: number;
    api_key?: string;
}

const initialState: CustomerState = {};

const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setCustomerState(state, action: PayloadAction<CustomerState>) {
            state.id = action.payload.id;
            state.api_key = action.payload.api_key;
        },
    },
});

export const { setCustomerState } = themeSlice.actions;
export default themeSlice.reducer;
