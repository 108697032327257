import {PayloadAction, createSlice} from "@reduxjs/toolkit";

interface SnackbarState {
    open: boolean;
    message: string;
}

interface SnackbarPayload {
    open: boolean;
    message?: string;
}

const initialState: SnackbarState = {open: false, message: ""};

const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setSnackbarOpen(state, action: PayloadAction<SnackbarPayload>) {
            state.open = action.payload.open;
            state.message = action.payload.message ? action.payload.message : "";
        },
    },
});

export const {setSnackbarOpen} = snackbarSlice.actions;
export default snackbarSlice.reducer;