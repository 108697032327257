import {Box} from "@mui/material";
import SourcesTable from "./SourcesTable/SourcesTable";

const Sources = () => {
    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                }}
            >
                <SourcesTable/>
            </Box>
        </Box>
    );
};

export default Sources;
