import UploadFileIcon from "@mui/icons-material/UploadFile";
import Sources from "../components/Dashboard/Sources/Sources";
import HubIcon from "@mui/icons-material/Hub";
import VSCodeExtension from "../components/Dashboard/Deployments/VSCodeExtension/VSCodeExtension";
import React from "react";
import Analytics from "../components/Dashboard/analytics/Analytics";
import BarChartIcon from "@mui/icons-material/BarChart";
import WebWidget from "../components/Dashboard/Deployments/WebWidget";
import TuneIcon from "@mui/icons-material/Tune";
import RAGConfiguration from "../components/Dashboard/RAGConfiguration/RAGConfiguration";

interface PageProps {
    name: string;
    description: string;
    icon: React.JSX.Element;
    children: React.ReactNode;
    path: string;
    pageTitle: string;
    parent?: string;
}

const pageProps: (PageProps)[] = [
    {
        name: "Sources",
        description:
            "Add & manage the Sources Integration Expert references to assist developers integrating Plaid.",
        icon: <UploadFileIcon/>,
        children: <Sources/>,
        path: "/Sources",
        pageTitle: "Layer - Sources",
    },
    {
        name: "Analytics",
        description:
            "Get analytics on how developers are using your Integration Expert.",
        icon: <BarChartIcon/>,
        children: <Analytics/>,
        path: "/analytics",
        pageTitle: "Layer - Analytics",
    },
    {
        name: "VS Code Extension",
        description:
            "Adjust how your Integration Expert extension will show up in VSCode’s marketplace.",
        icon: <HubIcon/>,
        children: <VSCodeExtension/>,
        path: "/deployments/vscode",
        pageTitle: "Layer - VS Code Extension Deployment",
        parent: "Deployments",
    },
    {
        name: "Website Widget",
        description: "Adjust how your website widget appears.",
        icon: <HubIcon/>,
        children: <WebWidget/>,
        path: "/deployments/web-widget",
        pageTitle: "Layer - Website Widget Deployment",
        parent: "Deployments",
    },
    {
        name: "RAG Configuration",
        description:
            "Make adjustments to our RAG system to optimize responses for your Integration Expert deployments.",
        icon: <TuneIcon />,
        children: <RAGConfiguration />,
        path: "/rag-configuration",
        pageTitle: "Layer - RAG Configuration",
    },
];

export default pageProps;
