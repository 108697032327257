import {Box, IconButton, Typography} from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LayerLogoDark from "../../assets/LayerLogoDark.svg";

const Header = ({expanded, setExpanded}: { expanded: boolean, setExpanded: (open: boolean) => void}) => {
    if (expanded) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    p: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
                    <img
                        src={LayerLogoDark}
                        alt={"Layer Logo"}
                        style={{width: "40px"}}
                    />
                    <Box
                        sx={{display: "flex", flexDirection: "column", gap: "2px"}}
                    >
                        <Typography variant="h1" sx={{fontSize: "1.1rem"}}>
                            Layer
                        </Typography>
                        <Typography
                            variant="h1"
                            sx={{fontSize: "1rem", fontWeight: "300"}}
                        >
                            Integration Expert
                        </Typography>
                        <Box
                            sx={{
                                mt: 0.5,
                                px: 1,
                                borderRadius: 1,
                                border: "1px solid",
                                borderColor: "primary.main",
                                display: "flex",
                                alignSelf: "flex-start",
                            }}
                        >
                            <Typography color="primary.main" variant="body2">
                                Beta
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <IconButton
                    onClick={() => setExpanded(false)}
                    sx={{height: "48px", width: "48px"}}
                >
                    <KeyboardDoubleArrowLeftIcon/>
                </IconButton>
            </Box>
        );
    } else {
        return (
            <Box sx={{p: 2, display: "flex", justifyContent: "center"}}>
                <IconButton onClick={() => setExpanded(true)}>
                    <KeyboardDoubleArrowRightIcon/>
                </IconButton>
            </Box>
        );
    }
};

export default Header;