import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import GoogleLogo from "../../../assets/google-logo.svg";
import React, {useState} from "react";
import {signInWithEmailAndPassword, signInWithPopup, AuthErrorCodes, AuthError} from "firebase/auth";
import {auth, provider} from "../../../clients/firebase";
import EmailAndPasswordForm from "../EmailAndPasswordForm/EmailAndPasswordForm";
import {useSnackbarContext} from "../../../contexts/SnackbarContext";

const LogIn = () => {
    const navigate = useNavigate();
    const {addMessage} = useSnackbarContext();

    const [loading, setLoading] = useState(false);

    function handleLogIn(email: string, password: string) {
        setLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .catch(err => {
                err = err as AuthError;
                if (
                    err.code === AuthErrorCodes.USER_DELETED ||
                    err.code === AuthErrorCodes.INVALID_PASSWORD
                ) {
                    addMessage("Incorrect email or password.", "error", 60);
                } else {
                    addMessage("Could not log in at this time.", "error", 60);
                    console.error(err);
                }
            })
            .finally(() => setLoading(false));
    }

    function handleGoogleSignUp() {
        setLoading(true);
        signInWithPopup(auth, provider)
            .catch((err) => {

                if (err.status === 401 || err.code === AuthErrorCodes.USER_DELETED) {
                    addMessage("Account not found. Please sign up first.", "error", 60);
                } else {
                    addMessage("Could not log in at this time.", "error", 60);
                    console.error(err);
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Typography variant="h2">Log In</Typography>
            <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                <EmailAndPasswordForm
                    submitLabel={"Log In"}
                    loading={loading}
                    submit={handleLogIn}
                />
                <Button
                    variant="contained"
                    onClick={handleGoogleSignUp}
                    sx={{
                        elevation: 0,
                        bgcolor: "white",
                        color: "#333333",
                        border: 1,
                        borderColor: "divider",
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        ":hover": {
                            bgcolor: "#F3F3F3",
                        },
                    }}
                >
                    <img
                        src={GoogleLogo}
                        alt="Google Logo"
                        style={{width: "24px", height: "24px"}}
                    />
                    Log in with Google
                </Button>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                        flexGrow: 1,
                    }}
                >
                    <Typography sx={{textAlign: "center", color: "#757575"}}>
                        Don't have an account yet?
                    </Typography>
                    <Button
                        variant="text"
                        onClick={() => navigate("/signup")}
                        sx={{py: 0, px: "2px", minWidth: 0}}
                    >
                        Sign up
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                        flexGrow: 1,
                    }}
                >
                    <Button
                        variant="text"
                        onClick={() => navigate("/reset-password")}
                        sx={{py: 0, px: "2px", minWidth: 0}}
                    >
                        Forgot Password?
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default LogIn;
