import { Box, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import { setDarkMode } from "../../state/themeSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { signOut } from "firebase/auth";
import { auth } from "../../clients/firebase";
import { useCustomerContext } from "../../contexts/CustomerContext";

const Footer = ({ expanded }: { expanded: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const customer = useCustomerContext();

  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2, gap: 2, pb: 3 }}>
      {/* TODO: Use browser preferred color scheme or move this */}
      {expanded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
            px: 2,
            borderRadius: 1,
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography>Dark Mode:</Typography>
          <Switch
            checked={darkMode}
            onChange={() => {
              dispatch(setDarkMode(!darkMode));
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
          overflow: "hidden",
          px: 2,
          py: 1,
          ...(!expanded && {
            justifyContent: "center",
            p: 1,
          }),
        }}
      >
        {expanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              textOverlow: "ellipsis",
            }}
          >
            <Typography noWrap>{customer.company_name}</Typography>
            <Typography variant="body2" noWrap>
              {customer.email}
            </Typography>
          </Box>
        )}
        <Tooltip
          title="Log out"
          placement="top"
          disableInteractive
          enterDelay={500}
        >
          <IconButton
            onClick={() => {
              signOut(auth).catch((err) => {
                console.error(err);
              });
            }}
          >
            <LogoutIcon sx={{ color: "#7B65FF" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Footer;
