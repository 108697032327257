import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import {auth} from "../clients/firebase";
import {
    ExtensionsDownloadObjectGetApiArg,
    WebWidgetsDownloadLogoGetApiArg,
} from "./layerApi";
import {RootState} from "./store";

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const firebaseAuth = (await auth.currentUser?.getIdToken()) || "";
    const apiKey = (api.getState() as RootState).customer.api_key;
    return fetchBaseQuery({
        baseUrl: process.env.REACT_APP_LAYER_URL || "",
        prepareHeaders: (headers: Headers) => {
            headers.set("Firebase-Auth", firebaseAuth);
            headers.set("Layer-Api-Key", apiKey || "");
            return headers;
        },
    })(args, api, extraOptions);
};

export const emptyApi = createApi({
    baseQuery: dynamicBaseQuery,
    tagTypes: ['extensions', 'web_widgets'],
    endpoints: (builder) => ({
        downloadExtensionObject: builder.query<string, ExtensionsDownloadObjectGetApiArg>({
            query: ({extensionId, specialObjectName}: ExtensionsDownloadObjectGetApiArg) => ({
                url: `extensions/${extensionId}/download/${specialObjectName}`,
                responseHandler: async (response): Promise<string> => {
                    if (!response.ok) throw new Error(response.statusText);
                    const extension = specialObjectName.split(".").pop();
                    switch (extension) {
                        case "jpg":
                        case "png":
                        case "gif":
                            return response.blob()
                                .then(blob => {
                                    return URL.createObjectURL(blob);
                                });
                        case "txt":
                        case "md":
                            let td = new TextDecoder("utf-8");
                            return response.arrayBuffer()
                                .then(input => {
                                    return td.decode(input);
                                });
                        default:
                            throw new Error(`Unrecognized extension type: ${extension}`);
                    }
                },
            }),
            providesTags: ['extensions'],
        }),
        downloadWebWidgetLogo: builder.query({
            queryFn: async (
                arg: WebWidgetsDownloadLogoGetApiArg,
                _,
                __,
                baseQuery,
            ) => {
                const response = await baseQuery({
                    url: `web_widgets/${arg.webWidgetId}/download-logo`,
                    responseHandler: async (response) => {
                        if (!response.ok) {
                            throw new Error(response.statusText);
                        }

                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);

                        return {data: url};
                    },
                });
                let data = response?.data;
                return {data: data};
            },
            providesTags: ['web_widgets'],
        }),
    }),
});

export const {
    useDownloadExtensionObjectQuery,
    useLazyDownloadWebWidgetLogoQuery,
} = emptyApi;
