import * as React from "react";
import {useLazyCustomersCheckNameAvailabilityGetQuery} from "../../../state/layerApi";
import {Box, TextField, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

interface CompanyNameFormProps {
    setCompanyName: (name: string) => void;
}

const CompanyNameForm = (props: CompanyNameFormProps) => {
    const [checkName] = useLazyCustomersCheckNameAvailabilityGetQuery();

    const [companyName, setCompanyName] = React.useState<string>("");
    const [companyNameError, setCompanyNameError] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (companyName.length === 0) return;

        setLoading(true);
        const timeoutId = setTimeout(() => {
            setLoading(true);
            checkName({companyName: companyName})
                .unwrap()
                .then(response => {
                    if (response.available) {
                        setCompanyNameError(undefined);
                    } else {
                        setCompanyNameError("Name already exists");
                    }
                })
                .catch(err => {
                    setCompanyNameError("Could not check company name at this time");
                    console.error(err);
                })
                .finally(() => setLoading(false));
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, [companyName, setLoading, checkName, setCompanyNameError]);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (loading || companyNameError !== undefined) return;
        props.setCompanyName(companyName);
    }

    return (
        <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{display: "flex", flexDirection: "column", gap: 1}}
        >
            <TextField
                required
                label={"Company Name"}
                variant={"filled"}
                autoComplete={"off"}
                type={"text"}
                onChange={(e) => setCompanyName(e.target.value)}
                error={companyNameError !== undefined}
                helperText={companyNameError}
            />
            <LoadingButton
                variant={"contained"}
                color={"primary"}
                type={"submit"}
                loading={loading}
            >
                <Typography variant="button">Next</Typography>
            </LoadingButton>
        </Box>
    );
};

export default CompanyNameForm;
