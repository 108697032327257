import React from "react";
import ModalDialog from "./ModalDialog";
import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface iDeleteConfirmationModal {
  modalOpen: boolean;
  itemName: string;
  setOpen: (open: boolean) => void;
  handleDelete: () => void;
}

const DeleteConfirmationModal = ({
  modalOpen,
  itemName,
  setOpen,
  handleDelete,
}: iDeleteConfirmationModal) => {
  function handleCloseModal() {
    setOpen(false);
  }

  function handleClickDelete() {
    handleDelete();
    handleCloseModal();
  }

  return (
    <>
      <ModalDialog
        label={"Delete " + itemName}
        modalOpen={modalOpen}
        handleClose={handleCloseModal}
      >
        <Typography>
          Are you sure you want to delete {itemName}? This action cannot be
          undone.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            flexGrow: 1,
            justifyContent: "flex-end",
          }}
        >
          <Button variant="text" color="primary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleClickDelete}
            sx={{ display: "flex", flexDirection: "row", gap: 1, px: 2 }}
          >
            <DeleteIcon />
            Delete
          </Button>
        </Box>
      </ModalDialog>
    </>
  );
};

export default DeleteConfirmationModal;
