import * as React from "react";
import {Location, useLocation} from "react-router-dom";
import {Box, Container, Typography} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import BarChartIcon from "@mui/icons-material/BarChart";
import HubIcon from "@mui/icons-material/Hub";
import Sources from "./Sources/Sources";
import Analytics from "./analytics/Analytics";
import VSCodeExtension from "./Deployments/VSCodeExtension/VSCodeExtension";
import WebWidget from "./Deployments/WebWidget";
import Crawls from "./Sources/Crawls/Crawls";
import ScrapeStatus from "./Sources/ScrapeStatus/ScrapeStatus";
import TuneIcon from "@mui/icons-material/Tune";
import RAGConfiguration from "./RAGConfiguration/RAGConfiguration";

type Page = 'sources' | 'analytics' | 'vscode' | 'web-widget' | 'rag-configuration';

function getPageFromLocation(location: Location<any>): Page {
    if (location.pathname.startsWith("/analytics")) return 'analytics';
    if (location.pathname.startsWith("/deployments")) {
        if (location.pathname.startsWith("/deployments/vscode")) return 'vscode';
        return 'web-widget';
    }
    if (location.pathname.startsWith("/rag-configuration")) return 'rag-configuration';
    return 'sources';
}

interface PageProps {
    name: string;
    description: string;
    icon: React.JSX.Element;
    children: React.ReactNode;
}

function getProps(page: Page): PageProps {
    switch (page) {
        case "sources":
            return {
                name: "Sources",
                description: "Add & manage the sources Integration Expert references to assist developers during integration.",
                icon: <UploadFileIcon sx={{fontSize: "32px"}}/>,
                children: <Sources/>,
            };
        case "analytics":
            return {
                name: "Analytics",
                description: "Get analytics on how developers are using your Integration Expert.",
                icon: <BarChartIcon sx={{fontSize: "32px"}}/>,
                children: <Analytics/>,
            };
        case "vscode":
            return {
                name: "VS Code Extension",
                description: "Adjust how your Integration Expert extension will show up in VSCode’s marketplace.",
                icon: <HubIcon sx={{fontSize: "32px"}}/>,
                children: <VSCodeExtension/>,
            };
        case "web-widget":
            return {
                name: "Website Widget",
                description: "Adjust how your website widget appears.",
                icon: <HubIcon sx={{fontSize: "32px"}}/>,
                children: <WebWidget/>,
            };
        case "rag-configuration":
            return {
                name: "RAG Configuration",
                description: "Make adjustments to our RAG system to optimize responses for your Integration Expert deployments.",
                icon: <TuneIcon sx={{fontSize: "32px"}}/>,
                children: <RAGConfiguration/>,
            };
    }
}

const Dashboard = () => {
    const location = useLocation();
    const [page, setPage] = React.useState<Page>(getPageFromLocation(location));

    React.useEffect(() => {
        setPage(getPageFromLocation(location));
    }, [location, setPage]);

    React.useEffect(() => {
        switch (page) {
            case "sources":
                document.title = "Layer - Sources";
                break;
            case "analytics":
                document.title = "Layer - Analytics";
                break;
            case "vscode":
                document.title = "Layer - VSCode";
                break;
            case "web-widget":
                document.title = "Layer - Widget";
                break;
            case "rag-configuration":
                document.title = "Layer - RAG Config";
                break;
        }
    }, [page]);

    const props = getProps(page);

    return (
        <>
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        py: 4,
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        {props.icon}
                        <Typography variant="h2">{props.name}</Typography>
                    </Box>
                    <Typography variant="subtitle1" sx={{mb: 2}}>
                        {props.description}
                    </Typography>
                    {props.children}
                </Box>
            </Container>
            <Crawls/>
            <ScrapeStatus/>
        </>
    );
};

export default Dashboard;
