import {Box, Button, Tab, Tabs, Typography} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import {LoadingButton} from "@mui/lab";
import PublishIcon from "@mui/icons-material/Publish";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import EmbeddableLinks from "../EmbeddableLinks";
import React from "react";
import {
    useExtensionsListTemplatesGetQuery,
    useLazyExtensionsPublishExtensionGetQuery
} from "../../../../state/layerApi";
import {useSnackbarContext} from "../../../../contexts/SnackbarContext";
import {FullExtension} from "../../../../shared/types";

interface ViewExtensionProps {
    extension: FullExtension;
    activateEditMode: () => void;
}

const ViewExtension = ({extension, activateEditMode}: ViewExtensionProps) => {
    const {addMessage} = useSnackbarContext();

    const [tab, setTab] = React.useState<"Readme" | "Copilot Chat Participant" | "Embeddable Links">("Readme");

    const {data: templates} = useExtensionsListTemplatesGetQuery();
    const [publishExtension, {isLoading}] = useLazyExtensionsPublishExtensionGetQuery();

    async function handlePublish() {
        if (templates === undefined) return;

        return publishExtension({
            extensionId: extension.id,
            templateName: templates.template_names.find(
                (t) => t === "vscode-activity-bar-template.zip",
            )
                ? "vscode-activity-bar-template.zip"
                : templates.template_names[0]!,
        })
            .unwrap()
            .then(() => {
                addMessage("Published extension!", "success", 60);
            })
            .catch(err => {
                console.error(err);
                addMessage("Failed to publish extension.", "error", 60);
            });
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <a
                    href={extension.marketplace_url}
                    target="_blank"
                    rel="noreferrer"
                    style={{textDecoration: "none"}}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            px: 2,
                            py: 1,
                        }}
                    >
                        <OpenInNewIcon/>
                        Open in Marketplace
                    </Button>
                </a>
                <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                    <Button
                        variant="contained"
                        onClick={activateEditMode}
                        sx={{
                            px: 2,
                            py: 1,
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                        }}
                    >
                        <EditIcon/>
                        Edit
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={isLoading || templates === undefined}
                        disabled={!extension.pending_publish}
                        onClick={handlePublish}
                        sx={{
                            px: 2,
                            py: 1,
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                        }}
                    >
                        <PublishIcon/>
                        Publish Changes
                    </LoadingButton>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 1,
                    border: 1,
                    borderColor: "divider",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 3,
                        p: 2,
                        bgcolor: "grey.100",
                        borderRadius: 1 / 1 / 0 / 0,  // Does this work?
                        alignItems: "center",
                    }}
                >
                    <Box sx={{width: "72px", height: "72px"}}>
                        <img
                            src={extension.logo_data}
                            alt="Logo"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                            gap: 1,
                            flexGrow: 1,
                        }}
                    >
                        <Typography variant="h1">
                            {extension.display_name}
                        </Typography>
                        <Typography>
                            {extension.description}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Tabs
                        value={tab}
                        onChange={(_, newValue) => setTab(newValue)}
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                        }}
                    >
                        <Tab value="Readme" label="Readme"/>
                        <Tab
                            value="Copilot Chat Participant"
                            label="Copilot Chat Participant"
                        />
                        <Tab value="Embeddable Links" label="Embeddable Links"/>
                    </Tabs>
                    <Box sx={{p: 2}}>
                        {tab === "Readme" && (
                            <ReactMarkdown remarkPlugins={[gfm]} className="markdown">
                                {extension.readme_data}
                            </ReactMarkdown>
                        )}
                        {tab === "Embeddable Links" && <EmbeddableLinks/>}
                        {tab === "Copilot Chat Participant" && (
                            <Box
                                sx={{display: "flex", flexDirection: "column", gap: 1}}
                            >
                                <Typography>
                                    This is how your participant will appear when invoked in
                                    Copilot chat:
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        border: "1px solid",
                                        borderColor: "divider",
                                        borderRadius: 1,
                                        p: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 1,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                px: 0.5,
                                                py: 0.1,
                                                color: "#2261B8",
                                                bgcolor: "#D5ECFF",
                                                borderRadius: 1,
                                            }}
                                        >
                                            @{extension.participant_name}
                                        </Typography>
                                        <Typography sx={{color: "grey.500"}}>
                                            {extension.participant_description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default ViewExtension;