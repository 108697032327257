import {IconButton, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import * as React from "react";

interface ActionsProps {
    id: number;
    name: string;
    type: string;
    onEditClick: () => void;
}

export default function Actions({id, name, type, onEditClick}: ActionsProps) {
    if (type === "website") {
        return (
            <>
                <Tooltip
                    title="Website sources cannot be edited. Please delete and add a new source."
                    disableInteractive
                    placement="top"
                >
                <span>
                  <IconButton disabled onClick={() => {
                  }}>
                    <EditIcon sx={{width: "20px", height: "20px"}}/>
                  </IconButton>
                </span>
                </Tooltip>
                <DeleteButton id={id} name={name}/>
            </>
        );
    } else {
        return (
            <>
                <EditButton onClick={onEditClick} type={type}/>
                <DeleteButton id={id} name={name}/>
            </>
        );
    }
}