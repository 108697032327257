import ModalDialog from "../../../../shared/ModalDialog";
import * as React from "react";
import Form, {Field} from "../../../../shared/Form";
import {FieldValues} from "react-hook-form";
import {
    SuggestionSource,
    SuggestionSourceUpdate,
    useSourcesUpdateDocumentSourcePatchMutation,
    useSourcesUpdateSuggestionSourcePatchMutation,
} from "../../../../state/layerApi";
import {Source} from "../../../../shared/types";
import {useSnackbarContext} from "../../../../contexts/SnackbarContext";

interface EditSourceModalProps {
    source?: Source;
    modalOpen: boolean;
    handleClose: () => void;
}

export default function EditSourceModal({source, modalOpen, handleClose}: EditSourceModalProps) {
    const {addMessage} = useSnackbarContext();
    const [putDocumentSource, {isLoading: isDocumentLoading}] = useSourcesUpdateDocumentSourcePatchMutation();
    const [putSuggestionSource, {isLoading: isSuggestionLoading}] = useSourcesUpdateSuggestionSourcePatchMutation();

    const loading = [isDocumentLoading, isSuggestionLoading].some(l => l);

    async function handleDocumentSubmit(values: FieldValues) {
        if (source === undefined) return;
        const formData = new FormData();
        if (values.name)
            formData.set('name', values.name);
        if (values.file)
            formData.set('file', values.file);
        return putDocumentSource({
            sourceId: source.id,
            // @ts-ignore
            documentSourceUpdate: formData,
        })
            .unwrap()
            .then(() => {
                addMessage("Source Updated", "success", 30);
                handleClose();
            })
            .catch(err => {
                console.error(err);
                addMessage("Error updating source", "error", 60);
            });
    }

    async function handleSuggestionSubmit(values: FieldValues) {
        if (source === undefined) return;
        const suggestionSourceUpdate: SuggestionSourceUpdate = {};
        if (values.name)
            suggestionSourceUpdate.name = values.name;
        if (values.question)
            suggestionSourceUpdate.question = values.question;
        if (values.answer)
            suggestionSourceUpdate.answer = values.answer;

        return putSuggestionSource({
            sourceId: source.id,
            suggestionSourceUpdate: suggestionSourceUpdate
        })
            .unwrap()
            .then(() => {
                addMessage("Source Updated", "success", 30);
                handleClose()
            })
            .catch(err => {
                console.error(err);
                addMessage("Error updating source", "error", 60);
            });
    }

    async function handleFormSubmit(values: FieldValues) {
        if (source === undefined) return;
        const sourceType = source.type;
        switch (sourceType) {
            case 'document':
                return handleDocumentSubmit(values);
            case 'suggestion':
                return handleSuggestionSubmit(values);
        }
    }

    const fields: Field[] = [];
    if (source !== undefined) {
        fields.push(
            {
                fieldName: "name",
                fieldLabel: "Name",
                fieldType: "text",
                fieldRequired: false,
                fieldDefaultValue: source.name,
            },
        );

        switch (source.type) {
            case 'document':
                fields.push({
                    fieldName: "file",
                    fieldLabel: "Document",
                    fieldType: "file",
                    fieldRequired: false,
                });
                break;
            case 'suggestion':
                fields.push(
                    {
                        fieldName: "question",
                        fieldLabel: "Question",
                        fieldType: "text",
                        fieldRequired: false,
                        fieldDefaultValue: (source as SuggestionSource).question,
                    },
                    {
                        fieldName: "answer",
                        fieldLabel: "Answer",
                        fieldType: "text",
                        fieldRequired: false,
                        fieldDefaultValue: (source as SuggestionSource).answer,
                    }
                );
                break;
        }
    }

    return (
        <>
            <ModalDialog
                label="Edit Source"
                modalOpen={modalOpen}
                handleClose={handleClose}
            >
                <Form
                    fields={fields}
                    handleFormSubmit={handleFormSubmit}
                    submitButtonLabel="Save Source"
                    submitButtonLoading={loading}
                />
            </ModalDialog>
        </>
    );
};