import {Box, Button, CircularProgress, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {
    CrawlSiteProgress,
    useCrawlsGetCrawlSiteProgressGetQuery,
} from "../../../../state/layerApi";
import {useAppSelector} from "../../../../state/hooks";
import {selectDarkMode} from "../../../../state/themeSlice";

interface CrawlStatusProps {
    id: string;
    onError: () => void;
    onDone: () => void;
    onClose: () => void;
}

function getCrawlMessage(progress: CrawlSiteProgress): string {
    if (progress.status === "DONE") return "Crawl Complete!";
    if (progress.status === "CANCELED") return "Crawl Canceled";
    if (progress.to_visit_urls.length === 0) return "Crawl Queued...";
    const hostname = new URL(progress.to_visit_urls[0] as string).hostname;
    return `Crawling ${hostname}`;
}

function getCrawlLoadingMessage(progress: CrawlSiteProgress): string | undefined {
    if (progress.status === "DONE" || progress.status === "CANCELED" || progress.to_visit_urls.length === 0) return undefined;
    const finishedCount = progress.visited_urls.length;
    const totalCount = progress.to_visit_urls.length + finishedCount;
    return `${finishedCount}/${totalCount}`;
}

const START_POLLING_INTERVAL_MS = 3000;
const MIN_POLLING_INTERVAL_MS = 1000;
const MAX_POLLING_INTERVAL_MS = 10000;
const POLLING_INTERVAL_STEP_MS = 500;

const CrawlStatus = (props: CrawlStatusProps) => {
    const darkMode = useAppSelector(selectDarkMode);

    const [message, setMessage] = React.useState<string>("Crawl Queued...");
    const [loadingMessage, setLoadingMessage] = React.useState<string | undefined>(undefined);
    const [pollingIntervalMs, setPollingIntervalMs] = React.useState<number>(START_POLLING_INTERVAL_MS);

    const {data: progress, error, fulfilledTimeStamp} = useCrawlsGetCrawlSiteProgressGetQuery(
        {jobKey: props.id},
        {
            pollingInterval: pollingIntervalMs,
            skipPollingIfUnfocused: false,
        },
    );
    const onError = props.onError;

    React.useEffect(() => {
        if (fulfilledTimeStamp === undefined) return;
        setPollingIntervalMs(prev => Math.min(MAX_POLLING_INTERVAL_MS, prev + POLLING_INTERVAL_STEP_MS));
    }, [fulfilledTimeStamp, setPollingIntervalMs]);

    React.useEffect(() => {
        if (progress === undefined) return;
        setMessage(getCrawlMessage(progress));
        setLoadingMessage(getCrawlLoadingMessage(progress));
        setPollingIntervalMs(prev => Math.max(MIN_POLLING_INTERVAL_MS, prev - 3 * POLLING_INTERVAL_STEP_MS));
    }, [progress, setMessage, setLoadingMessage, setPollingIntervalMs]);

    React.useEffect(() => {
        if (error === undefined) return;
        console.error(JSON.stringify(error));
        onError();
    }, [error, onError]);

    const child = (progress === undefined || !(progress.status === "DONE")) ? (
        <Box sx={{display: "flex", gap: 2, alignItems: "center"}}>
            {loadingMessage !== undefined && (
                <Typography variant="body2">
                    {loadingMessage}
                </Typography>
            )}
            <CircularProgress size={20}/>
        </Box>
    ) : (
        <>
            <Button
                variant="text"
                size="small"
                sx={{p: 1}}
                onClick={props.onDone}
            >
                Select Sources to Add
            </Button>
            <IconButton onClick={props.onClose}>
                <CloseIcon
                    sx={{color: darkMode ? "#333" : "#fff"}}
                />
            </IconButton>
        </>
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 1,
                px: 2,
                py: 1,
                bgcolor: darkMode ? "#fff" : "grey.800",
            }}
        >
            <Typography variant="body2">{message}</Typography>
            <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
                {child}
            </Box>
        </Box>
    );
}

export default CrawlStatus;