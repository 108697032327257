import * as React from "react";
import {useLocation, Location} from "react-router-dom";
import SignUp from "./SignUp/SignUp";
import LogIn from "./LogIn/LogIn";
import {Box, Typography} from "@mui/material";
import LayerLogoDark from "../../assets/LayerLogoDark.svg";
import DashboardPreview from "../../assets/DashboardPreview.png";
import ResetPassword from "./ResetPassword/ResetPassword";

type Page = 'signup' | 'login' | 'reset-password';

function getPageFromLocation(location: Location<any>): Page {
    if (location.pathname.startsWith("/reset-password")) return 'reset-password';
    if (location.pathname.startsWith("/signup")) return 'signup';
    return 'login';
}

const Auth = () => {
    const location = useLocation();
    const [page, setPage] = React.useState<Page>(getPageFromLocation(location));

    React.useEffect(() => {
        setPage(getPageFromLocation(location));
    }, [location, setPage]);

    React.useEffect(() => {
        switch (page) {
            case "signup":
                document.title = "Layer - Sign Up";
                break;
            case "login":
                document.title = "Layer - Log In";
                break;
            case "reset-password":
                document.title = "Layer - Reset Password";
                break;
            default:
                document.title = "Layer";
        }
    }, [page]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    position: "absolute",
                    top: "32px",
                    left: "32px",
                }}
            >
                <img src={LayerLogoDark} alt={"Layer Logo"} style={{width: "40px"}}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "2px"}}>
                    <Typography variant="h1" sx={{fontSize: "1.1rem"}}>
                        Layer
                    </Typography>
                    <Typography variant="h1" sx={{fontSize: "1rem", fontWeight: "300"}}>
                        Integration Expert
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100vw",
                    height: "100vh",
                    alignItems: "stretch",
                }}
            >
                <Box
                    sx={{
                        minWidth: "60%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "500px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "stretch",
                            gap: 3,
                        }}
                    >
                        {page === "reset-password" ? <ResetPassword/> : (page === "signup" ? <SignUp/> : <LogIn/>)}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: "5%",
                        gap: 8,
                        justifyContent: "end",
                        alignItems: "start",
                        bgcolor: "#7C7799",
                        color: "white",
                        boxSizing: "border-box",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            pr: "5%",
                        }}
                    >
                        <Typography variant="h2">
                            Make your API easier to integrate
                        </Typography>
                        <Typography>
                            Just upload your documentation and reference materials and we’ll
                            deploy your Integration Expert to VS Code, Github Copilot, and
                            more.
                        </Typography>
                    </Box>
                    <img
                        src={DashboardPreview}
                        alt="Layer Management Platform Dashboard"
                        style={{
                            flexGrow: 1,
                            width: "100%",
                            maxHeight: "60%",
                            objectFit: "cover",
                            objectPosition: "top left",
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default Auth;