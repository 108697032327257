import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {formatDateTime} from "../../../../utils/dateTime";
import * as React from "react";
import {Source} from "../../../../shared/types";
import Actions from "./Actions";
import Content from "./Content";

export function SourcesTableColumns(onEditClick: (source: Source) => void): GridColDef[] {
    return [
        {
            field: "type",
            headerName: "Type",
            type: "string",
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                const source = params.row as Source;
                if (source.type === "website") {
                    return "🔗\xa0\xa0Website";
                } else if (source.type === "document") {
                    return "📄\xa0\xa0Document";
                } else if (source.type === "suggestion") {
                    return "❓\xa0\xa0Suggestion";
                }
            },
        },
        {
            field: "name",
            headerName: "Name",
            flex: 2,
        },
        {
            field: "created_at",
            headerName: "Created On",
            type: "dateTime",
            valueGetter: (value) => value && new Date(value + "Z"),
            valueFormatter: (value?: Date) => {
                if (value == null) {
                    return "";
                }
                return formatDateTime(value);
            },
            flex: 1,
        },
        {
            field: "updated_at",
            headerName: "Updated On",
            type: "dateTime",
            valueGetter: (value) => value && new Date(value + "Z"),
            valueFormatter: (value?: Date) => {
                if (value == null) {
                    return "";
                }
                return formatDateTime(value);
            },
            flex: 1,
        },
        {
            field: "collection_id",
            headerName: "Collection",
            type: "string",
            sortable: false,
            hideable: false,
            flex: 1,
        },
        {
            field: "content",
            headerName: "Content",
            type: "string",
            sortable: false,
            filterable: false,
            flex: 2,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                return <Content source={params.row as Source} value={params.value}/>;
            },
        },
        {
            field: "actions",
            headerName: "",
            type: "actions",
            resizable: false,
            sortable: false,
            filterable: false,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                const source = params.row as Source;
                return <Actions
                    id={source.id}
                    name={source.name}
                    type={source.type}
                    onEditClick={() => onEditClick(source)}
                />;
            },
            width: 100,
        },
    ];
}
