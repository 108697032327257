import {useSourcesDeleteSourceDeleteMutation} from "../../../../state/layerApi";
import * as React from "react";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationModal from "../../../../shared/DeleteConfirmationModal";
import {useSnackbarContext} from "../../../../contexts/SnackbarContext";

interface DeleteButtonProps {
    id: number;
    name: string;
}

export default function DeleteButton({id, name}: DeleteButtonProps) {
    const {addMessage} = useSnackbarContext();
    const [deleteSource, {isLoading}] = useSourcesDeleteSourceDeleteMutation();

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    const handleDelete = () => {
        deleteSource({sourceId: id})
            .then(() => addMessage("Source deleted", "success", 30))
            .catch(err => {
                console.error(err);
                addMessage("Failed to delete source", "error", 60);
            });
    };

    return (
        <>
            <IconButton onClick={() => setModalOpen(true)} disabled={isLoading}>
                <DeleteIcon sx={{width: "20px", height: "20px"}}/>
            </IconButton>
            <DeleteConfirmationModal
                modalOpen={modalOpen}
                itemName={name}
                setOpen={setModalOpen}
                handleDelete={handleDelete}
            />
        </>
    );
}