import {Box, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import Form, {Field} from "../../../../shared/Form";
import {useExtensionsUpdateExtensionPutMutation} from "../../../../state/layerApi";
import {useSnackbarContext} from "../../../../contexts/SnackbarContext";

type FieldValues = {
    name: string;
    participantName: string;
    participantDescription: string;
    displayName: string;
    description: string;
    logoData?: string;
    readmeData?: string;
};

interface EditExtensionProps {
    deactivateEditMode: () => void;
    initialValues?: FieldValues;
    extensionId?: number;
}

const EditExtension = ({deactivateEditMode, initialValues, extensionId}: EditExtensionProps) => {
    const {addMessage} = useSnackbarContext();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [putExtension] = useExtensionsUpdateExtensionPutMutation();

    const formFields: Field[] = [
        {
            fieldName: "logoData",
            fieldLabel: "Extension Icon",
            fieldType: "file",
            fieldRequired: false,
            fieldAcceptedFileExtensions: [".png", ".jpg"],
            fieldImageMinSize: {width: 128, height: 128},
            fieldImageCrop: {
                aspectRatio: 1,
                minSize: {width: 128, height: 128},
                rescaleImageWidth: 256,
            },
        },
        {
            fieldName: "displayName",
            fieldLabel: "Extension Display Name",
            fieldType: "text",
            fieldDefaultValue: initialValues?.displayName,
            fieldRequired: true,
            fieldHelperText:
                "The display name for the extension used in the Marketplace.",
        },
        {
            fieldName: "description",
            fieldLabel: "Extension Description",
            fieldType: "textarea",
            fieldDefaultValue: initialValues?.description,
            fieldRequired: true,
            fieldStartingRows: 2,
        },
        {
            fieldName: "readmeData",
            fieldLabel: "Extension Overview",
            fieldType: "markdown",
            fieldDefaultValue: initialValues?.readmeData,
            fieldRequired: true,
        },
        {
            fieldName: "participantName",
            fieldLabel: "Copilot Chat Participant Name",
            fieldType: "text",
            fieldDefaultValue: initialValues?.participantName,
            fieldHelperText:
                "This is how your participant will be invoked, prefixed by @ in Copilot chat.",
            fieldRequired: true,
            fieldValidation: (name: string) => {
                if (name.includes(" ")) {
                    return "Participant name cannot contain spaces.";
                }
            },
        },
        {
            fieldName: "participantDescription",
            fieldLabel: "Copilot Chat Participant Description",
            fieldType: "text",
            fieldDefaultValue: initialValues?.participantDescription,
            fieldHelperText:
                "This will appear as the placeholder text in Copilot chat while your participant is invoked.",
            fieldRequired: true,
        },
    ];

    async function handleFormSubmit(values: FieldValues) {
        if (loading || extensionId === undefined) return;

        setLoading(true);

        const formData = new FormData();
        formData.append("display_name", values.displayName);
        formData.append("description", values.description);
        formData.append("participant_name", values.participantName);
        formData.append("participant_description", values.participantDescription);

        if (values.readmeData) {
            const readmeFile = new File([
                new Blob([values.readmeData], {type: "text/markdown"})
            ], "readme.md");
            formData.append("readme_file", readmeFile);
        }

        if (values.logoData) {
            formData.append("logo_file", values.logoData);
        }

        putExtension({
            extensionId: extensionId,
            // @ts-ignore
            extensionUpdate: formData,
        }).unwrap()
            .then(() => {
                addMessage("Extension updated!", "success", 30);
                deactivateEditMode();
            })
            .catch(err => {
                console.error(err);
                addMessage("Failed to update extension", "error", 60);
            })
            .finally(() => setLoading(false));
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <Typography variant="h3" sx={{flexGrow: 1}}>
                    Edit VS Code Extension
                </Typography>
                <IconButton onClick={deactivateEditMode}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Form
                fields={formFields}
                handleFormSubmit={(fields) => handleFormSubmit(fields as FieldValues)}
                submitButtonLabel="Save Changes"
                submitButtonLoading={loading}
            />
        </Box>
    );
}

export default EditExtension;