import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";

interface EditButtonProps {
    type: string;
    onClick: () => void;
}

export default function EditButton({onClick, type}: EditButtonProps) {
    return (
        <IconButton onClick={onClick} disabled={type === "website"}>
            <EditIcon sx={{width: "20px", height: "20px"}}/>
        </IconButton>
    );
}