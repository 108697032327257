import * as React from "react";
import {ListItem, Tooltip, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

interface NavListingProps {
    expanded: boolean;
    active: boolean;
    path: string;
    name: string;
    icon: React.ReactElement;
}

const NavListing = ({expanded, active, path, name, icon}: NavListingProps) => {
    const navigate = useNavigate();

    if (expanded) {
        return (
            <ListItem
                onClick={() => navigate(path)}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    p: 2,
                    borderRadius: 2,
                    ":hover": {
                        backgroundColor: "grey.200",
                        cursor: "pointer",
                    },
                    ...(active && {
                        bgcolor: "primary.100",
                        color: "primary.main",
                        ":hover": {backgroundColor: "primary.200"},
                    }),
                }}
            >
                {icon}
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: active ? "600" : "500",
                    }}
                >
                    {name}
                </Typography>
            </ListItem>
        );
    } else {
        return (
            <Tooltip title={name} disableInteractive enterDelay={500}>
                <ListItem
                    onClick={() => navigate(path)}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        p: 2,
                        borderRadius: 2,
                        ":hover": {
                            backgroundColor: "grey.200",
                            cursor: "pointer",
                        },
                        justifyContent: "center",
                        ...(active && {
                            bgcolor: "primary.100",
                            color: "primary.main",
                            ":hover": {backgroundColor: "primary.200"},
                        }),
                    }}
                >
                    {icon}
                </ListItem>
            </Tooltip>
        );
    }
};

export default NavListing;
