import {createContext, ReactNode, useContext, useState} from "react";
import {AlertColor} from "@mui/material/Alert/Alert";

interface snack {
    message: string;
    severity: AlertColor;
    autoHideDurationSeconds: number | undefined;
}

interface snackbarState {
    snacks: snack[];
    addMessage: (message: string, severity: AlertColor, autoHideDurationSeconds?: number) => void;
    removeMessage: () => void;
}

const SnackbarContext = createContext<snackbarState>({
    snacks: [],
    addMessage: () => {},
    removeMessage: () => {},
});

export function SnackbarContextProvider({children}: { children: ReactNode }) {
    const snackbarContext = useSnackbarContextSetup();
    return <SnackbarContext.Provider value={snackbarContext}>{children}</SnackbarContext.Provider>;
}

export function useSnackbarContext() {
    return useContext(SnackbarContext);
}

export function useSnackbarContextSetup(): snackbarState {
    const [snacks, setSnacks] = useState<snack[]>([]);

    const addMessage = (message: string, severity: AlertColor, autoHideDurationSeconds?: number) => {
        setSnacks((prevSnacks) => {
            return [
                ...prevSnacks,
                {
                    message: message,
                    severity: severity,
                    autoHideDurationSeconds: autoHideDurationSeconds,
                },
            ];
        });
    };

    const removeMessage = () => {
        if (snacks.length < 1) return;
        setSnacks((prevProps) => {
            return prevProps.slice(1);
        });
    };

    return {snacks, addMessage, removeMessage};
}