import * as React from 'react';
import {Box, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSnackbarContext} from "../../../contexts/SnackbarContext";

interface EmailAndPasswordFormProps {
    submitLabel: string;
    loading: boolean;
    submit: (email: string, password: string) => void;
}

const EmailAndPasswordForm = ({submitLabel, submit, loading}: EmailAndPasswordFormProps) => {
    const {addMessage} = useSnackbarContext();

    const [email, setEmail] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<string | undefined>(undefined);
    const [password, setPassword] = React.useState<string>("");
    const [passwordError, setPasswordError] = React.useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        const nextEmail = e.currentTarget.value;
        setEmail(nextEmail);
        if (e.currentTarget.validity.valid) {
            setEmailError(undefined);
        } else {
            setEmailError("Invalid email address");
        }
    }

    function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
        const nextPassword = e.currentTarget.value;
        setPassword(nextPassword);
        if (e.currentTarget.validity.valid && nextPassword.length >= 8) {
            setPasswordError(undefined);
        } else {
            setPasswordError("Must be at least 8 characters");
        }
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (submitting) return;

        setSubmitting(true);
        if (emailError === undefined && passwordError === undefined) {
            submit(email, password);
        } else {
            addMessage("Could not log in at this time.", "error", 60);
            console.error("Cannot submit at this time");
        }
        setSubmitting(false);
    }

    return (
        <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
            }}
        >
            <TextField
                required
                label={"Email"}
                variant={"filled"}
                autoComplete={"off"}
                type={"email"}
                onChange={handleEmailChange}
                error={emailError !== undefined}
                helperText={emailError}
            />
            <TextField
                required
                label={"Password"}
                variant={"filled"}
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                onChange={handlePasswordChange}
                error={passwordError !== undefined}
                helperText={passwordError}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={"end"}>
                            <IconButton
                                aria-label={"toggle password visibility"}
                                onClick={() => setShowPassword((prev) => !prev)}
                            >
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <LoadingButton
                variant={"contained"}
                color={"primary"}
                type={"submit"}
                loading={loading}
            >
                <Typography variant="button">{submitLabel}</Typography>
            </LoadingButton>
        </Box>
    );
}

export default EmailAndPasswordForm;