import * as React from "react";
import {Box, Button} from "@mui/material";
import {
    GridRowSelectionModel,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {useSourcesDeleteSourcesPostMutation} from "../../../../state/layerApi";
import DeleteConfirmationModal from "../../../../shared/DeleteConfirmationModal";
import {useSnackbarContext} from "../../../../contexts/SnackbarContext";
import AddSourceModal from "./AddSourceModal";

interface SourceGridToolbarProps {
    rowSelectionModel: GridRowSelectionModel;
}

const SourceGridToolbar = ({rowSelectionModel}: SourceGridToolbarProps) => {
    const {addMessage} = useSnackbarContext();
    const [deleteSources, {isLoading}] = useSourcesDeleteSourcesPostMutation();

    const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
    const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);

    const handleDeleteSourceRows = () => {
        const sourceIds = rowSelectionModel.map(id => id as number);
        deleteSources({deleteSources: {ids: sourceIds}})
            .unwrap()
            .then(() => addMessage(rowSelectionModel.length > 1 ? "Sources deleted" : "Source deleted", "success", 30))
            .catch(err => {
                console.error(err);
                addMessage(rowSelectionModel.length > 1 ? "Error deleting sources" : "Error deleting source", "error", 60);
            });
    };

    return (
        // TODO: Adjust these styles
        <>
            <GridToolbarContainer>
                <Box
                    sx={{
                        display: "flex",
                        gap: 4,
                        alignItems: "center",
                        p: "12px",
                        borderBottom: 1,
                        borderColor: "divider",
                        width: "100%",
                    }}
                >
                    <GridToolbarQuickFilter sx={{flexGrow: 1}} variant="filled"/>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "12px",
                            height: "48px",
                        }}
                    >
                        <Button
                            variant="outlined"
                            disabled={rowSelectionModel.length === 0 || isLoading}
                            color="error"
                            onClick={() => setDeleteModalOpen(true)}
                            sx={{minWidth: 0, width: "56px", height: "48px"}}
                        >
                            <DeleteIcon/>
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setAddModalOpen(true)}
                            sx={{minWidth: 0, width: "56px", height: "48px"}}
                        >
                            <AddIcon/>
                        </Button>
                    </Box>
                </Box>
            </GridToolbarContainer>
            <DeleteConfirmationModal
                modalOpen={deleteModalOpen}
                itemName={
                    rowSelectionModel.length > 1
                        ? rowSelectionModel.length + " Sources"
                        : "1 Source"
                }
                setOpen={setDeleteModalOpen}
                handleDelete={handleDeleteSourceRows}
            />
            <AddSourceModal modalOpen={addModalOpen} handleClose={() => setAddModalOpen(false)}/>
        </>
    );
};

export default SourceGridToolbar;
