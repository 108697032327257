import {Snackbar} from "@mui/material";
import * as React from "react";
import {EmbedJobsProgress, layerApi, useEmbedsGetEmbedJobProgressGetQuery} from "../../../../state/layerApi";
import {useSnackbarContext} from "../../../../contexts/SnackbarContext";
import {useAppDispatch} from "../../../../state/hooks";

const START_POLLING_INTERVAL_MS = 5000;
const MIN_POLLING_INTERVAL_MS = 3000;
const MAX_POLLING_INTERVAL_MS = 10000;
const POLLING_INTERVAL_STEP_MS = 500;

function getScrapeMessage(progress: EmbedJobsProgress): string {
    switch (progress.status) {
        case "QUEUED":
            return "Scrape Queued...";
        case "CANCELED":
            return "Scrape Canceled";
        case "DONE":
            return "Sources Added";
        default:
            const remaining = progress.total - progress.finished;
            return `${remaining} Remaining`;
    }
}

const ScrapeStatus = () => {
    const {addMessage} = useSnackbarContext();
    const appDispatch = useAppDispatch();

    const [message, setMessage] = React.useState<string>("Scrape Queued...");
    const [open, setOpen] = React.useState<boolean>(false);
    const [pollingIntervalMs, setPollingIntervalMs] = React.useState<number>(START_POLLING_INTERVAL_MS);

    const {data: progress, error, fulfilledTimeStamp} = useEmbedsGetEmbedJobProgressGetQuery(
        undefined,
        {
            pollingInterval: pollingIntervalMs,
            skipPollingIfUnfocused: false,
        },
    );

    React.useEffect(() => {
        if (fulfilledTimeStamp === undefined) return;
        setPollingIntervalMs(prev => Math.min(MAX_POLLING_INTERVAL_MS, prev + POLLING_INTERVAL_STEP_MS));
    }, [fulfilledTimeStamp, setPollingIntervalMs]);

    React.useEffect(() => {
        if (progress === undefined) {
            setOpen(false);
            return;
        }

        setMessage(getScrapeMessage(progress));
        setOpen(progress.status !== "CANCELED" && progress.status !== "DONE" && progress.total > 0);
        setPollingIntervalMs(prev => Math.max(MIN_POLLING_INTERVAL_MS, prev - 3 * POLLING_INTERVAL_STEP_MS));
    }, [progress, setOpen, setMessage, setPollingIntervalMs]);

    React.useEffect(() => {
        if (progress === undefined) return;
        appDispatch(layerApi.util.invalidateTags(["sources"]));
        if (progress?.status === "DONE") addMessage(`Source${progress.finished > 1 ? "s" : ""} Added`, "success", 60);
        else if (progress?.status === "CANCELED") addMessage("Scrape Canceled", "info", 60);
    }, [progress]);

    React.useEffect(() => {
        if (error === undefined) return;
        console.error(JSON.stringify(error));
    }, [error]);

    return (
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            open={open}
            message={message}
            autoHideDuration={3000}
            ContentProps={{
                sx: {
                    display: "block",
                    textAlign: "center",
                },
            }}
        />
    );
};

export default ScrapeStatus;