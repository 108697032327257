import {useSnackbarContext} from "../../contexts/SnackbarContext";
import {Alert, Snackbar as MUISnackbar} from "@mui/material";
import {useEffect, useState} from "react";
import {AlertColor} from "@mui/material/Alert/Alert";

const Snackbar = () => {
    const {snacks, removeMessage} = useSnackbarContext();
    const [open, setOpen] = useState(snacks.length > 0);

    useEffect(() => {
        setOpen(snacks.length > 0);
    }, [snacks, setOpen]);

    const handleClose = () => {
        if (!open) return;
        setOpen(false);
        setTimeout(() => {
            removeMessage();
        }, 500);
    };

    let snack;
    if (snacks.length === 0) {
        snack = {
            severity: "info" as AlertColor,
            message: "",
            autoHideDurationSeconds: null,
        };
    } else {
        snack = snacks[0];
    }

    return (
        <MUISnackbar
            open={open}
            autoHideDuration={snack.autoHideDurationSeconds ? snack.autoHideDurationSeconds * 1000 : snack.autoHideDurationSeconds}
            onClose={handleClose}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        >
            <Alert
                onClose={handleClose}
                severity={snack.severity}
                sx={{width: "100%"}}
            >
                {snack.message}
            </Alert>
        </MUISnackbar>
    );
};

export default Snackbar;