import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
  MultiSelectElement,
  useForm,
  PasswordElement,
  CheckboxElement,
  TextareaAutosizeElement,
} from "react-hook-form-mui";
import { DropzoneAreaBase, FileObject } from "mui-file-dropzone";
import { useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../state/store";
import { setImageCropModalOpen } from "../state/imageCropModalOpenSlice";
import ImageCropDialog from "./ImageCropDialog";
import Resizer from "react-image-file-resizer";

// --------------- Types ---------------
type BaseField = {
  fieldRequired: boolean;
  fieldName: string;
  fieldLabel: string;
  fieldHelperText?: string;
  fieldDependentOnName?: string;
  fieldDependentValue?: any;
  fieldOnChange?: (value: any) => void;
};

type TextField = BaseField & {
  fieldType: "text";
  fieldMinLength?: number;
  fieldDefaultValue?: string;
  fieldValidation?: (
    value: string
  ) => string | undefined | Promise<string | undefined>;
};

type EmailField = BaseField & {
  fieldType: "email";
  fieldMinLength?: number;
  fieldDefaultValue?: string;
  fieldValidation?: (
    value: string
  ) => string | undefined | Promise<string | undefined>;
};

type TextAreaField = BaseField & {
  fieldType: "textarea";
  fieldMinLength?: number;
  fieldStartingRows?: number;
  fieldDefaultValue?: string;
  fieldValidation?: (
    value: string
  ) => string | undefined | Promise<string | undefined>;
};

type MarkdownField = BaseField & {
  fieldType: "markdown";
  fieldDefaultValue?: string;
};

type SelectField = BaseField & {
  fieldType: "select";
  fieldOptions: { id: string; label: string }[];
  fieldDefaultValue?: string;
};

type MultiSelectField = BaseField & {
  fieldType: "select-multiple";
  fieldOptions: { id: string; label: string }[];
  fieldDefaultValue?: string[];
};

type AutoCompleteField = BaseField & {
  fieldType: "autocomplete";
  fieldOptions: string[];
  fieldDefaultValue?: string;
  multiple: false;
  freeSolo?: boolean;
};

type AutoCompleteFieldMultiple = BaseField & {
  fieldType: "autocomplete";
  fieldOptions: string[];
  fieldDefaultValue?: string[];
  multiple?: true;
  freeSolo?: boolean;
};

type PasswordField = BaseField & {
  fieldType: "password";
  fieldMinLength?: number;
  fieldDefaultValue?: string;
  fieldValidation?: (
    value: string
  ) => string | undefined | Promise<string | undefined>;
};

type CheckboxField = BaseField & {
  fieldType: "checkbox";
  fieldDefaultValue?: boolean;
};

type FileField = BaseField & {
  fieldType: "file";
  fieldDefaultValue?: File;
  fieldAcceptedFileExtensions?: string[];
  fieldImageCrop?: {
    aspectRatio?: number;
    minSize: { width: number; height: number };
    maxSize?: { width: number; height: number };
    rescaleImageWidth?: number;
  };
  fieldImageMinSize?: { width: number; height: number };
};

interface IForm {
  fields: Field[];
  handleFormSubmit: (values: {}) => void;
  submitButtonLabel?: string;
  submitButtonLoading?: boolean;
}

export type Field =
  | TextField
  | EmailField
  | TextAreaField
  | MarkdownField
  | SelectField
  | MultiSelectField
  | AutoCompleteField
  | AutoCompleteFieldMultiple
  | PasswordField
  | CheckboxField
  | FileField;

// --------------- Components ---------------
export const Form = ({
  fields,
  handleFormSubmit,
  submitButtonLabel = "Submit",
  submitButtonLoading = false,
}: IForm) => {
  const defaultValues = fields.reduce(
    (acc: Record<string, string | string[] | boolean | File>, field) => {
      if (field.fieldDefaultValue) {
        acc[field.fieldName] = field.fieldDefaultValue;
      }
      return acc;
    },
    {}
  );

  const fileFieldName = (
    fields.find((field) => field.fieldType === "file") as FileField
  )?.fieldName;

  const dispatch = useDispatch<AppDispatch>();

  // TODO: Fix this to work on multiple markdown fields
  const [markdownValue, setMarkdownValue] = useState(
    (fields.find((field) => field.fieldType === "markdown") as MarkdownField)
      ?.fieldDefaultValue
  );
  const markdownFieldName = (
    fields.find((field) => field.fieldType === "markdown") as MarkdownField
  )?.fieldName;

  //TODO: Fix this to work on multiple autocomplete fields
  const [autocompleteValue, setAutocompleteValue] = useState<
    string | string[] | undefined
  >(
    (
      fields.find(
        (field) => field.fieldType === "autocomplete"
      ) as AutoCompleteField
    )?.fieldDefaultValue
  );
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const autocompleteFieldName = (
    fields.find(
      (field) => field.fieldType === "autocomplete"
    ) as AutoCompleteField
  )?.fieldName;

  const formContext = useForm({ defaultValues });
  const { handleSubmit, getValues, watch } = formContext;

  function handleAddFile(newFiles: FileObject[], field: FileField) {
    setShowFileError(false);
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileObjects: FileObject[] = newFiles.map((fileObject) => ({
        file: fileObject.file,
        data: e.target?.result as string,
      }));
      if (fileObjects[0].file.type.includes("image")) {
        const img = new Image();
        img.src = e.target?.result as string;
        img.onload = () => {
          if (
            field.fieldImageMinSize &&
            (img.width < field.fieldImageMinSize.width ||
              img.height < field.fieldImageMinSize.height)
          ) {
            setFileErrorMessage(
              "Image must be at least " +
                field.fieldImageMinSize.width +
                "x" +
                field.fieldImageMinSize.height +
                " pixels"
            );
            setShowFileError(true);
            return;
          } else {
            if (field.fieldImageCrop) {
              dispatch(
                setImageCropModalOpen({
                  open: true,
                  imageUrl: e.target?.result as string,
                  originalFileName: newFiles[0].file.name,
                  originalFileType: newFiles[0].file.type,
                  aspectRatio: field.fieldImageCrop.aspectRatio,
                  minSize: field.fieldImageCrop.minSize,
                })
              );
            } else {
              setFileObjects(fileObjects);
            }
          }
        };
      } else {
        setFileObjects(fileObjects);
      }
    };
    reader.readAsDataURL(newFiles[0].file);
  }

  function isFileField(field: Field): field is FileField {
    return field.fieldType === "file";
  }

  function rescaleImage(image: Blob) {
    const fileField = fields.find(isFileField) as FileField;
    let rescaleWidth: number = 300;
    let aspectRatio: number = 1;
    if (fileField && fileField.fieldImageCrop?.rescaleImageWidth) {
      rescaleWidth = fileField.fieldImageCrop.rescaleImageWidth;
    }
    if (fileField && fileField.fieldImageCrop?.aspectRatio) {
      aspectRatio = fileField.fieldImageCrop.aspectRatio;
    }
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        image,
        rescaleWidth,
        rescaleWidth * aspectRatio,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  }

  async function handleCropComplete(image: Blob) {
    try {
      const resizedImageBlob = await rescaleImage(image);
      if (resizedImageBlob instanceof Blob) {
        const resizedImage = new File([resizedImageBlob], originalFileName, {
          type: "image/png",
        });
        const reader = new FileReader();
        reader.onload = (e) => {
          setFileObjects([
            {
              file: resizedImage,
              data: e.target?.result as string,
            },
          ]);
        };
        reader.onerror = (e) => {
          console.error("FileReader error", e);
        };
        reader.readAsDataURL(resizedImage);
      }
    } catch (err) {
      console.error("Error resizing image", err);
    }
  }

  async function validateField(field: Field, value: string) {
    if (
      field.fieldType === "text" ||
      field.fieldType === "email" ||
      field.fieldType === "textarea" ||
      field.fieldType === "password"
    ) {
      if (field.fieldValidation) {
        const result = await field.fieldValidation(value);
        return result === undefined ? true : result;
      }
      if (field.fieldMinLength && value.length < field.fieldMinLength) {
        return (
          field.fieldLabel +
          " must be at least " +
          field.fieldMinLength +
          " characters long."
        );
      }
    }
    return true;
  }

  function handleFieldChange(field: Field) {
    const dependentFields = fields.filter(
      (f) => f.fieldDependentOnName === field.fieldName
    );
    dependentFields.forEach((dependentField) => {
      if (dependentField.fieldDependentValue !== getValues()[field.fieldName]) {
        if (dependentField.fieldDefaultValue) {
          formContext.setValue(
            dependentField.fieldName,
            dependentField.fieldDefaultValue
          );
        } else {
          formContext.resetField(dependentField.fieldName);
        }
      }
    });
    if (field.fieldOnChange) field.fieldOnChange(getValues()[field.fieldName]);
  }

  function handleSuccess() {
    const fieldValues = getValues();
    let values = { ...fieldValues };
    if (markdownFieldName) {
      values[markdownFieldName] = markdownValue ? markdownValue : "";
    }
    if (autocompleteFieldName && autocompleteValue) {
      values[autocompleteFieldName] = autocompleteValue;
    } else if (autocompleteFieldName && autocompleteInputValue !== "") {
      values[autocompleteFieldName] = [autocompleteInputValue];
    }
    if (fileObjects.length > 0) {
      values[fileFieldName] = fileObjects[0].file;
    } else if (
      fields.find(
        (field) =>
          field.fieldType === "file" &&
          field.fieldRequired &&
          (field.fieldDependentValue
            ? field.fieldDependentValue ===
              fieldValues[
                field.fieldDependentOnName ? field.fieldDependentOnName : ""
              ]
            : true)
      )
    ) {
      setFileErrorMessage("This field is required");
      setShowFileError(true);
      return;
    }
    if (showFileError) {
      return;
    }
    handleFormSubmit(values);
  }

  const originalFileName = useSelector(
    (state: RootState) => state.imageCropModalOpen.originalFileName
  );
  const originalFileType = useSelector(
    (state: RootState) => state.imageCropModalOpen.originalFileType
  );

  // TODO: Fix this to work on multiple file fields
  // TODO: Allow default values on fileObjects
  const [fileObjects, setFileObjects] = useState<FileObject[]>([]);

  const [showFileError, setShowFileError] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState(
    "This field is required"
  );

  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(handleSuccess)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {fields.map((field, index) => {
          const dependentFieldValue = field.fieldDependentOnName
            ? watch(field.fieldDependentOnName)
            : null;

          if (
            field.fieldDependentOnName &&
            dependentFieldValue !== field.fieldDependentValue
          ) {
            return null;
          }

          switch (field.fieldType) {
            case "text":
              return (
                <TextFieldElement
                  key={index}
                  name={field.fieldName}
                  label={field.fieldLabel}
                  required={field.fieldRequired}
                  helperText={
                    field.fieldHelperText ? field.fieldHelperText : ""
                  }
                  rules={{
                    validate: async (value) => {
                      return await validateField(field, value);
                    },
                  }}
                  variant="filled"
                  onChange={() => handleFieldChange(field)}
                />
              );
            case "email":
              return (
                <TextFieldElement
                  key={index}
                  name={field.fieldName}
                  label={field.fieldLabel}
                  required={field.fieldRequired}
                  helperText={
                    field.fieldHelperText ? field.fieldHelperText : ""
                  }
                  type="email"
                  rules={{
                    validate: async (value) => {
                      return await validateField(field, value);
                    },
                  }}
                  variant="filled"
                  onChange={() => handleFieldChange(field)}
                />
              );
            case "textarea":
              return (
                <TextareaAutosizeElement
                  key={index}
                  name={field.fieldName}
                  label={field.fieldLabel}
                  required={field.fieldRequired}
                  rows={field.fieldStartingRows ? field.fieldStartingRows : 5}
                  resizeStyle="none"
                  helperText={
                    field.fieldHelperText ? field.fieldHelperText : ""
                  }
                  rules={{
                    validate: async (value) => {
                      return await validateField(field, value);
                    },
                  }}
                  variant="filled"
                  onChange={() => handleFieldChange(field)}
                />
              );
            case "markdown":
              // TODO: Allow this to be required
              return (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography>{field.fieldLabel}</Typography>
                  <MDEditor
                    value={markdownValue}
                    onChange={(value) => setMarkdownValue(value ? value : "")}
                    highlightEnable={false}
                    previewOptions={{
                      rehypePlugins: [[rehypeSanitize]],
                    }}
                  />
                </Box>
              );
            case "select":
              return (
                <SelectElement
                  key={index}
                  name={field.fieldName}
                  label={field.fieldLabel}
                  options={field.fieldOptions}
                  required={field.fieldRequired}
                  helperText={
                    field.fieldHelperText ? field.fieldHelperText : ""
                  }
                  variant="filled"
                  onChange={() => handleFieldChange(field)}
                />
              );
            case "select-multiple":
              return (
                <MultiSelectElement
                  key={index}
                  name={field.fieldName}
                  label={field.fieldLabel}
                  options={field.fieldOptions}
                  required={field.fieldRequired}
                  helperText={
                    field.fieldHelperText ? field.fieldHelperText : ""
                  }
                  variant="filled"
                  onChange={() => handleFieldChange(field)}
                />
              );
            case "autocomplete":
              return (
                <Autocomplete
                  key={index}
                  defaultValue={field.fieldDefaultValue}
                  multiple={field.multiple}
                  freeSolo={field.freeSolo}
                  options={field.fieldOptions}
                  value={autocompleteValue}
                  onChange={(_, newValue) => {
                    if (field.multiple) {
                      setAutocompleteValue(newValue as string[]);
                    } else {
                      setAutocompleteValue(newValue as string);
                    }
                  }}
                  onInputChange={(_, newInputValue) => {
                    if (field.freeSolo) {
                      setAutocompleteInputValue(newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        {...params}
                        required={field.fieldRequired}
                        variant="filled"
                        label={field.fieldLabel}
                      />
                      {field.fieldHelperText && (
                        <Typography
                          sx={{
                            mx: "14px",
                            mt: "3px",
                            fontSize: "12px",
                            color: "grey.700",
                          }}
                        >
                          {field.fieldHelperText}
                        </Typography>
                      )}
                    </Box>
                  )}
                />
              );
            case "password":
              return (
                <PasswordElement
                  key={index}
                  name={field.fieldName}
                  label={field.fieldLabel}
                  required={field.fieldRequired}
                  helperText={
                    field.fieldHelperText ? field.fieldHelperText : ""
                  }
                  rules={{
                    validate: async (value) => {
                      return await validateField(field, value);
                    },
                  }}
                  variant="filled"
                  onChange={() => handleFieldChange(field)}
                />
              );
            case "checkbox":
              return (
                <CheckboxElement
                  key={index}
                  name={field.fieldName}
                  label={field.fieldLabel}
                  required={field.fieldRequired}
                  helperText={
                    field.fieldHelperText ? field.fieldHelperText : ""
                  }
                  onChange={() => handleFieldChange(field)}
                />
              );
            case "file":
              return (
                <>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Typography>{field.fieldLabel}</Typography>
                    <Box
                      className={
                        showFileError ? "file-dropzone error" : "file-dropzone"
                      }
                    >
                      <DropzoneAreaBase
                        key={fileObjects[0]?.file.size}
                        acceptedFiles={field.fieldAcceptedFileExtensions}
                        fileObjects={fileObjects}
                        filesLimit={1}
                        maxFileSize={50000000}
                        alertSnackbarProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                          },
                        }}
                        showAlerts={["error"]}
                        showFileNames
                        useChipsForPreview={
                          !(
                            field.fieldAcceptedFileExtensions?.includes(
                              ".jpg"
                            ) ||
                            field.fieldAcceptedFileExtensions?.includes(
                              ".jpeg"
                            ) ||
                            field.fieldAcceptedFileExtensions?.includes(".png")
                          )
                        }
                        showPreviews
                        previewText="File:"
                        showPreviewsInDropzone={false}
                        onDelete={(deletedFileObject: FileObject) => {
                          setFileObjects([]);
                          setFileErrorMessage("This field is required");
                          setShowFileError(true);
                        }}
                        onAdd={(newFileObjects: FileObject[]) =>
                          handleAddFile(newFileObjects, field)
                        }
                      />
                      {field.fieldAcceptedFileExtensions && (
                        <Typography
                          sx={{
                            mt: "3px",
                            fontSize: ".75rem",
                            lineHeight: "1.66",
                          }}
                        >
                          {"Accepted file types: " +
                            field.fieldAcceptedFileExtensions?.join(", ")}
                        </Typography>
                      )}
                      {showFileError && (
                        <Typography
                          sx={{
                            mx: "14px",
                            mt: "3px",
                            color: "#d32f2f",
                            fontSize: ".75rem",
                            lineHeight: "1.66",
                          }}
                        >
                          {fileErrorMessage}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <ImageCropDialog
                    originalImage={new File([], "")}
                    minSize={
                      field.fieldImageMinSize
                        ? field.fieldImageMinSize
                        : { width: 10, height: 10 }
                    }
                    aspectRatio={field.fieldImageCrop?.aspectRatio}
                    handleCropComplete={handleCropComplete}
                  />
                </>
              );
            default:
              return null;
          }
        })}
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={submitButtonLoading}
        >
          <Typography variant="button">{submitButtonLabel}</Typography>
        </LoadingButton>
      </Box>
    </FormContainer>
  );
};

export default Form;
