import BarChartIcon from "@mui/icons-material/BarChart";
import {
    Box,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import {
    DataGrid,
    DataGridProps,
    GridColDef,
    GridOverlay,
} from "@mui/x-data-grid";
import AnalyticsGridToolbar from "./AnalyticsGridToolbar";
import {formatDateTime} from "../../../utils/dateTime";
import {
    MessageLog,
    useChatGetMessageLogsGetQuery,
} from "../../../state/layerApi";
import {useMemo, useState} from "react";
import TableRowsIcon from "@mui/icons-material/TableRows";
import AnalyticsBarChart from "./AnalyticsBarChart";

function AnalyticsDataGrid({
                               columns,
                               rows,
                               dataGridPropOverrides,
                               initialStateOverrides,
                           }: {
    columns: GridColDef[];
    rows: any[];
    dataGridPropOverrides?: Omit<Partial<DataGridProps>, "columns" | "rows">;
    initialStateOverrides?: Partial<DataGridProps["initialState"]>;
}) {
    return (
        <DataGrid
            columns={columns}
            rows={rows}
            sx={{maxHeight: "475px"}}
            rowSelection={false}
            pageSizeOptions={[10, 25, 50, 100]}
            columnVisibilityModel={{
                id: false,
            }}
            initialState={{
                pagination: {
                    paginationModel: {pageSize: 10},
                },
                ...initialStateOverrides,
            }}
            slots={{
                toolbar: () => <AnalyticsGridToolbar/>,
                noRowsOverlay: () => (
                    <GridOverlay>
                        <Typography variant="body2" color="GrayText">
                            No Data Available
                        </Typography>
                    </GridOverlay>
                ),
                noResultsOverlay: () => (
                    <GridOverlay>
                        <Typography variant="body2" color="GrayText">
                            No Results
                        </Typography>
                    </GridOverlay>
                ),
            }}
            {...dataGridPropOverrides}
        />
    );
}

const Analytics = () => {
    const [queriesView, setQueriesView] = useState<"list" | "chart">("list");
    const [usersView, setUsersView] = useState<"list" | "chart">("list");

    const {data: queries} = useChatGetMessageLogsGetQuery();

    const queryRows = useMemo(() => {
        return queries?.map((query) => {
            return {
                id: query.id,
                userIp: query.ip,
                query: query.message,
                timestamp: new Date(query.created_at! + "Z"),
            };
        });
    }, [queries]);

    const queryChartData = useMemo(() => {
        let chartData: { data: { date: Date; value: number }[] }[] = [{data: []}];
        queryRows?.forEach((query) => {
            chartData[0].data.push({date: query.timestamp, value: 1});
        });
        return chartData;
    }, [queryRows]);

    const userRows = useMemo(() => {
        const userCounts = queries?.reduce(
            (
                acc: Record<string, { count: number; lastActive: Date }>,
                query: MessageLog,
            ) => {
                if (query.ip in acc) {
                    acc[query.ip].count += 1;
                    if (new Date(query.created_at!) > acc[query.ip].lastActive) {
                        acc[query.ip].lastActive = new Date(query.created_at! + "Z");
                    }
                } else {
                    acc[query.ip] = {count: 1, lastActive: new Date(query.created_at!)};
                }
                return acc;
            },
            {},
        );

        if (!userCounts) return [];

        return Object.entries(userCounts).map(([ip, data]) => ({
            userIp: ip,
            messageCount: data.count,
            lastActive: data.lastActive,
            id: ip,
        }));
    }, [queries]);

    const userChartData = useMemo(() => {
        let chartData: {
            data: { date: Date; value: number; duplicateId: string }[];
        }[] = [{data: []}];
        queryRows?.forEach((query) => {
            chartData[0].data.push({
                date: query.timestamp,
                value: 1,
                duplicateId: query.userIp,
            });
        });
        return chartData;
    }, [queryRows]);

    const queriesTableColumns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            width: 200,
            type: "number",
        },
        {field: "query", headerName: "Query", flex: 1, type: "string"},
        {
            field: "userIp",
            headerName: "User IP",
            width: 200,
            type: "string",
        },
        {
            field: "timestamp",
            headerName: "Timestamp",
            width: 200,
            type: "dateTime",
            renderCell: (params) => {
                return formatDateTime(params.value);
            },
        },
    ];

    const usersTableColumns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            width: 200,
            type: "number",
        },
        {
            field: "userIp",
            headerName: "User IP",
            flex: 1,
            type: "string",
        },
        {
            field: "messageCount",
            headerName: "Message Count",
            width: 150,
            type: "number",
        },
        {
            field: "lastActive",
            headerName: "Last Active",
            width: 200,
            type: "dateTime",
            renderCell: (params) => {
                if (!params.value) return "";
                return formatDateTime(params.value);
            },
        },
    ];

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 4}}>
            <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">Queries</Typography>
                    <ToggleButtonGroup
                        size="small"
                        value={queriesView}
                        exclusive
                        onChange={(_, value) => {
                            if (value) {
                                setQueriesView(value);
                            }
                        }}
                    >
                        <ToggleButton value="list">
                            <TableRowsIcon/>
                        </ToggleButton>
                        <ToggleButton value="chart">
                            <BarChartIcon/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {queriesView === "list" ? (
                    <AnalyticsDataGrid
                        columns={queriesTableColumns}
                        rows={queryRows || []}
                        initialStateOverrides={{
                            sorting: {
                                sortModel: [{field: "timestamp", sort: "desc"}],
                            },
                        }}
                    />
                ) : (
                    <AnalyticsBarChart chartData={queryChartData}/>
                )}
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">Users</Typography>
                    <ToggleButtonGroup
                        size="small"
                        value={usersView}
                        exclusive
                        onChange={(_, value) => {
                            if (value) {
                                setUsersView(value);
                            }
                        }}
                    >
                        <ToggleButton value="list">
                            <TableRowsIcon/>
                        </ToggleButton>
                        <ToggleButton value="chart">
                            <BarChartIcon/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {usersView === "list" ? (
                    <AnalyticsDataGrid
                        columns={usersTableColumns}
                        rows={userRows || []}
                        initialStateOverrides={{
                            sorting: {
                                sortModel: [{field: "last-active", sort: "desc"}],
                            },
                        }}
                    />
                ) : (
                    <AnalyticsBarChart
                        chartData={userChartData}
                        label={"Active Users"}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Analytics;
