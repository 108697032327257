import * as React from "react";
import { Box, Typography } from "@mui/material";
import { gridClasses, GridOverlay, GridRowId } from "@mui/x-data-grid";
import { useSourcesGetSourcesGetQuery } from "../../../../state/layerApi";
import SourceGridToolbar from "./SourceGridToolbar";
import { SourcesTableColumns } from "./SourcesTableColumns";
import EditSourceModal from "./EditSourceModal";
import { groupSites } from "../../../../shared/siteUtils";
import GroupedDataGrid from "../../../../shared/GroupedDataGrid";
import { Source } from "../../../../shared/types";

function groupSources(sources: Source[]) {
  if (!sources) return [];

  let groupedSources = [];

  const websiteSources = sources?.reduce(
    (acc, source) => {
      if (source.url && source.type === "website") {
        acc.push({ sourceLink: source.url });
      }
      return acc;
    },
    [] as { sourceLink: string }[],
  );

  if (websiteSources) {
    const groups = groupSites(
      websiteSources.map((source) => source.sourceLink),
      1,
      3,
      true,
    );
    for (const group in groups) {
      if (group !== "ungrouped") {
        const parentURL = new URL(
          group.split(",")[group.split(",").length - 1],
        );
        const childSources = sources.map((source) => {
          if (source.url ? source.url.includes(parentURL.toString()) : false) {
            return source;
          }
          return undefined;
        });
        const latestChild = childSources
          .filter((source) => source !== undefined)
          .reduce((prev, current) => {
            return new Date(prev?.updated_at ?? "") >
              new Date(current?.updated_at ?? "")
              ? prev
              : current;
          }, childSources[0]);

        const highestIdSource = sources.reduce((prev, current) => {
          return prev.id > current.id ? prev : current;
        }, sources[0]);

        groupedSources.push({
          id: highestIdSource.id + groupedSources.length + 1000,
          // TODO: Handle this better
          url: parentURL.toString(),
          type: "website",
          name:
            parentURL.pathname === "/"
              ? parentURL.toString()
              : parentURL.pathname,
          updated_at: latestChild?.updated_at,
          created_at: latestChild?.created_at,
          collection_id: latestChild?.collection_id,
          group,
          parent: true,
        });
      }
      groups[group].forEach((sourceLink: string) => {
        const initialRow = sources.find((source) =>
          source.type === "website" ? source.url === sourceLink : false,
        );
        groupedSources.push({
          ...initialRow,
          group,
          parent: false,
        });
      });
    }
  }

  for (const source of sources) {
    if (source.type !== "website") {
      groupedSources.push({
        ...source,
        group: "ungrouped",
        parent: false,
      });
    }
  }

  return groupedSources;
}

export default function SourcesTable() {
  const { data: sources, isLoading } = useSourcesGetSourcesGetQuery();

  const [selectedSources, setSelectedSources] = React.useState<GridRowId[]>([]);
  const [editSource, setEditSource] = React.useState<Source | undefined>(
    undefined,
  );

  const groupedSources = React.useMemo(() => {
    return groupSources(sources || []);
  }, [sources]);

  return (
    <>
      <Box>
        <GroupedDataGrid
          nestedGroups
          itemLabel="source"
          countParents={false}
          indentChildren={false}
          columns={SourcesTableColumns(setEditSource)}
          groupedRows={groupedSources}
          hiddenColumns={["collection_id"]}
          defaultSort={{ field: "updated_at", order: "desc" }}
          updateSelectedRows={setSelectedSources}
          dataGridSlots={{
            toolbar: () => (
              <SourceGridToolbar rowSelectionModel={selectedSources} />
            ),
            noRowsOverlay: () => (
              <GridOverlay>
                <Typography variant="body2" color="GrayText">
                  {isLoading ? "Loading Sources..." : "No Sources Added"}
                </Typography>
              </GridOverlay>
            ),
            noResultsOverlay: () => (
              <GridOverlay>
                <Typography variant="body2" color="GrayText">
                  No Results
                </Typography>
              </GridOverlay>
            ),
          }}
          dataGridPropOverrides={{
            sx: {
              [`& .${gridClasses.cell}`]: {
                py: 1,
                display: "flex",
                alignItems: "center",
              },
            },
            disableColumnSorting: true,
            getRowHeight: () => "auto",
            getEstimatedRowHeight: () => 59,
          }}
        />
      </Box>
      <EditSourceModal
        source={editSource}
        modalOpen={editSource !== undefined}
        handleClose={() => setEditSource(undefined)}
      />
    </>
  );
}
